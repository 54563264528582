import React from "react";
import Layout from "../components/layout";
import styled, { css } from "styled-components";
import { Container, Col, Row } from "../components/container";
import Heading from "../components/heading";
import { Button, ButtonGroup } from "../components/button";
import NHSLogo from "../images/nhs.png";
import DiabetesUKLogo from "../images/diabetesUK.png";

const StyledHeading = styled(Heading)`
  padding-top: ${props => props.theme.spacing.verySmall};
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledParagraph = styled.p`
  padding-top: ${props => props.theme.spacing.regular};
  margin-top: 0;
  margin-bottom: 0;

  ${props =>
    props.bold &&
    css`
      font-weight: 700;
    `}
`;

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing.veryLarge};
  margin-bottom: ${props => props.theme.spacing.huge};
`;

const StyledLogoRow = styled(Row)`
  margin-top: ${props => props.theme.spacing.regular};
  align-items: flex-end;
  justify-content: space-between;

  img {
    width: 35%;
    max-width: 100%;
  }
`;

const StyledQuestion = styled.div`
  margin-top: ${props => props.theme.spacing.veryLarge};
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${props => props.theme.spacing.regular};
`;

const ChoiceButton = ({ selected = false, ...props }) => {
  return selected ? (
    <Button {...props} />
  ) : (
    <Button outline={true} type={Button.types.secondary} {...props} />
  );
};

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      monitor: null,
      confidence: null
    };
  }

  typeSelected(type) {
    this.setState({
      type
    });
  }

  monitorSelected(monitor) {
    this.setState({
      monitor
    });
  }

  confidenceSelected(confidence) {
    this.setState({
      confidence
    });
  }

  render() {
    return (
      <Layout
        rightButtonLink="/"
        rightButtonText="Help"
        leftButtonLink="/what"
        leftButtonText="Back"
      >
        <Container>
          <Row>
            <Col>
              <StyledHeading elem="h1" size={Heading.sizes.veryLarge}>
                A bit about you
              </StyledHeading>
              <StyledParagraph>
                Select from the options below so that we can show you the
                information that's most relevant to you.
              </StyledParagraph>
              <StyledQuestion>
                <strong>Do you have type 1 or type 2 diabetes?</strong>
                <StyledButtonGroup vertical={false}>
                  <ChoiceButton
                    onClick={() => this.typeSelected(1)}
                    selected={this.state.type === 1}
                  >
                    Type 1
                  </ChoiceButton>
                  <ChoiceButton
                    onClick={() => this.typeSelected(2)}
                    selected={this.state.type === 2}
                  >
                    Type 2
                  </ChoiceButton>
                </StyledButtonGroup>
              </StyledQuestion>

              <StyledQuestion>
                <strong>How do you monitor your blood glucose?</strong>
                <p>
                  Do you have a continuous monitoring device, or do you
                  regularly complete fingerprick tests (intermittent
                  monitoring)?
                </p>
                <StyledButtonGroup vertical={false}>
                  <ChoiceButton
                    onClick={() => this.monitorSelected(1)}
                    selected={this.state.monitor === 1}
                  >
                    Intermittent
                  </ChoiceButton>
                  <ChoiceButton
                    onClick={() => this.monitorSelected(2)}
                    selected={this.state.monitor === 2}
                  >
                    Continuous
                  </ChoiceButton>
                </StyledButtonGroup>
              </StyledQuestion>
              <StyledQuestion>
                <strong>
                  How confident do you feel managing your diabetes?
                </strong>
                <StyledButtonGroup vertical={false}>
                  <ChoiceButton
                    onClick={() => this.confidenceSelected("😀")}
                    selected={this.state.confidence === "😀"}
                  >
                    😀
                  </ChoiceButton>
                  <ChoiceButton
                    onClick={() => this.confidenceSelected("😐")}
                    selected={this.state.confidence === "😐"}
                  >
                    😐
                  </ChoiceButton>
                  <ChoiceButton
                    onClick={() => this.confidenceSelected("🙁")}
                    selected={this.state.confidence === "🙁"}
                  >
                    🙁
                  </ChoiceButton>
                </StyledButtonGroup>
              </StyledQuestion>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledButton to="/interests">My preferences</StyledButton>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default IndexPage;
